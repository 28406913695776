<template>
  <div>
    <van-nav-bar title="我的团队" left-arrow @click-left="toUcenter" />
    <van-tabs v-model="active" sticky @click="handleTagsClick">
      <van-tab title="我发起的">
        <GroupOnTeamList ref="list_1" :leader-flag="1" :app-id="appId" />
      </van-tab>
      <van-tab title="我参与的">
        <GroupOnTeamList ref="list_0" :leader-flag="0" :app-id="appId" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import GroupOnTeamList from './components/GroupOnTeamList'
export default {
  components: {
    GroupOnTeamList
  },
  data() {
    return {
      appId: '',
      searchKey: '',
      dataList: [],
      loading: false,
      finished: false,
      maxId: ''
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {},
  methods: {
    toUcenter() {
      this.$router.push(`/${this.appId}/ucenter`)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
