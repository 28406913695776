<template>
  <div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="initDataList">
      <van-card v-for="item in dataList" :key="item.id" :desc="item.activityDescn" :title="item.activityTitle" :thumb="item.activityCover"
        @click-thumb="toActivity(item)">
        <template #price-top>
          <div class="order-num">{{ item.id }}</div>
        </template>
        <template #tags>
          <van-tag plain type="danger">{{ item.teamStatus | formatTeamStatus }}</van-tag>
        </template>
        <template #footer>
          <van-button size="mini" type="primary" @click="handleShowMemberList(item)">队员列表</van-button>
        </template>
      </van-card>
    </van-list>
    <van-dialog v-model="displayDialog" title="队员列表">
      <ul class="member-list">
        <li v-for="item in memberList" :key="item.id" class="member-wrapper">
          <van-badge :content="item.leaderFlag? '队长':''">
            <span class="img-wrapper"><img :src="item.avatar" class="avatar" /></span>
          </van-badge>
          <span class="title">{{ item.nickname }}</span>
        </li>
      </ul>
    </van-dialog>
  </div>
</template>
<script>
import groupOnTeamApi from '@/api/group-on-team'
export default {
  props: {
    leaderFlag: {
      type: Number,
      default: 1
    },
    appId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      dataList: [],
      maxId: '',
      searckKey: '',
      memberList: [],
      displayDialog: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    toActivity(item) {
      this.$router.push(`/${this.appId}/groupOn/join/${item.activityId}/${item.id}`)
    },
    initDataList() {
      groupOnTeamApi.list({ maxId: this.maxId, leaderFlag: this.leaderFlag }).then(res => {
        if (res.succ) {
          let items = res.data.items
          if (items.length === 0) {
            this.finished = true
          } else {
            for (let i = 0; i < items.length; i++) {
              this.dataList.push(items[i])
            }
            this.loading = false
            this.maxId = items[items.length - 1].id
          }
        }
      })
    },
    handleShowMemberList(item) {
      groupOnTeamApi.getMembers(item.id).then(res => {
        if (res.succ) {
          this.memberList = res.data.items
          this.displayDialog = true
        }
      })
    },
    // 清空列表
    clearDataList() {
      this.maxId = ''
      this.finished = false
      this.dataList = []
    },
    // 清空列表，重新加载
    reloadDataList() {
      this.clearDataList()
      this.initDataList()
    }
  }
}
</script>
<style lang="less" scoped>
.member-list {
  .member-wrapper {
    display: flex;
    align-items: center;
    padding: 6px 30px;
    justify-content: space-around;
    .van-badge {
      min-width: 40px;
    }
    span.img-wrapper {
      width: 40px;
      img.avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #999;
      }
    }
    span.title {
      flex: 1;
    }
  }
}
</style>
