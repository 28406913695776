import request from '@/utils/request'

const prefix = '/api/groupOnTeam'

export default {
  getMembers(id) {
    return request.get(`${prefix}/${id}/members`)
  },
  list(params) {
    return request.get(`${prefix}/list`, {params})
  }


}
